<template>
  <section id="InstancesIndex">
    <app-page-title>
      <h1>Listagem de Funções</h1>
      <div class="align-self-end">
        <div class="text-xs-right">
          <app-form-modal
            class-icon="icon-add"
            :title-dialog="(isNew ? 'Adicionar' : 'Editar') + ' Função'"
            :confirmAction="saveInstance"
            :cancelAction="clearData"
            :formValid="!$v.Instance.$error && Instance.Code.length > 0"
            ref="modal"
          >
            <div class="form-group">
              <v-text-field
                v-model="Instance.Name"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
                label="Nome da Função"
                required
              ></v-text-field>
              <v-text-field
                v-model="Instance.Code"
                :error="$v.Instance.Code.$error"
                @blur="$v.Instance.Code.$touch()"
                label="Código da Função"
                required
              ></v-text-field>
            </div>
          </app-form-modal>
        </div>
      </div>
    </app-page-title>
    <v-container grid-list>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        :pageable="true"
        :skip="skip"
        :take="take"
        :total="grid.pagging.total"
        @pagechange="pageChange"
        :sortable="true"
        :sort="grid.sort"
        @sortchange="sortChange"
        :filterable="true"
        :filter="grid.filter"
        @filterchange="filterChange"
        @edit="edit"
        @remove="remove"
      >
        <GridNoRecords>Não existem funções</GridNoRecords>
      </Grid>
    </v-container>
  </section>
</template>

<script>
import * as kendo from "@progress/kendo-ui";
import FormModalComponent from "@/components/modals/form-modal";
import { required, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import instancesService from "@/services/api/jobFunctionsService";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import StringFilterCell from "@/components/grid/string-filter.js";
import GridHelper from "@/utilities/grid";

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  data() {
    return {
      Instance: {},
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          field: "Code",
          title: "Código",
          width: 300,
          filterCell: StringFilterCell,
        },
        {
          field: "Name",
          title: "Nome",
          filterCell: StringFilterCell,
        },
        {
          cell: EditCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          filterable: false,
          sortable: false,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdJobFunction == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    filterChange(ev) {
      this.grid.pagging.pageId = 1;
      this.grid.filter = ev.filter;
      this.loadItems();
    },
    sortChange(ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        this.grid.filter,
        this.grid.sort
      );
      await instancesService
        .Get(options)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
          this.resetInstance();
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = { IdJobFunction: 0, Code: "", Name: "" };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await instancesService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a centro de custo com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await instancesService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o centro de estudo com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdJobLocation == this.Instance.IdJobLocation
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdJobFunction;
      kendo.confirm("Tem a certeza que quer apagar a função?").done(() => {
        instancesService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a função com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
  async created() {
    this.resetInstance();
    await this.loadItems();
  },
};
</script>

<style></style>
