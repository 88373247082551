<template>
  <v-dialog v-model="isOpen" persistent max-width="600px">
    <v-btn
      v-if="allowButton"
      slot="activator"
      :color="colorButton"
      :class="classButton"
    >
      <i v-if="classIcon" :class="classIcon"></i>
      {{ titleButton }}
    </v-btn>
    <v-card>
      <v-form @submit.prevent="saveInstance">
        <v-card-title>
          <span class="headline">{{ titleDialog }}</span>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="default"
            class="text--left"
            type="button"
            @click.prevent="cancel"
            >Cancelar</v-btn
          >
          <v-btn
            :loading="isLoading"
            :disabled="!formValid"
            color="accent"
            type="submit"
            class="ml-auto"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    allowButton: { type: Boolean, default: true },
    titleDialog: { type: String, default: "Adicionar" },
    titleButton: { type: String, default: "Adicionar" },
    colorButton: { type: String, default: "accent" },
    classIcon: { type: String, default: null },
    classButton: { type: String, default: null },
    formValid: { type: Boolean, default: true },
    confirmAction: {
      type: Function,
      default: function (callback) {
        callback(true);
      },
    },
    cancelAction: {
      type: Function,
      default: function (callback) {
        callback(true);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$emit("opened", true);
      } else {
        this.$emit("closed", true);
      }
    },
  },
  methods: {
    cancel() {
      var that = this;
      this.cancelAction(function (success) {
        that.isLoading = false;
        if (success) {
          that.close();
        }
      });
    },
    saveInstance() {
      if (!this.formValid) {
        return;
      }
      if (!this.isLoading) {
        this.isLoading = true;
        var that = this;
        this.confirmAction(function (success) {
          that.isLoading = false;
          if (success) {
            that.close();
          }
        });
      }
    },
    open() {
      this.isLoading = false;
      this.isOpen = true;
    },
    close() {
      this.isLoading = false;
      this.isOpen = false;
    },
  },
};
</script>

<style scoped></style>
