import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const instance = axios.create();

const baseUrl = API_URL + "api/portfoliocategories";

const endPoints = {
  Get: baseUrl + "/fetch",
  Update: baseUrl + "/update",
  Create: baseUrl + "/create",
  Delete: baseUrl + "/delete",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(options) {
    return instance.post(endPoints.Get, { ...{ Filters: [] }, ...options });
  },
  GetSingle(id, options) {
    return instance.get(endPoints.Get + "/" + id, { params: options });
  },
  Delete(id, options) {
    const data = { ...options };
    return instance.delete(endPoints.Delete + "/" + id, { data });
  },
  Update(item, options = {}) {
    const data = { Instance: item, ...options };
    return instance.put(endPoints.Update, data);
  },
  Create(item, options = {}) {
    const data = { Instance: item, ...options };
    return instance.post(endPoints.Create, data);
  },
};

export default actions;
